<template>
	<div class="g-flow flex-center">
		<div v-for="(item, i) in node" :key="i" class="item" :class="{'z-crt': i == currentIndex, 'z-err': reject && i == currentIndex}">
			<div class="icon flex-center">
				<i v-if="reject && i == currentIndex" class="tf tf-cross"></i>
				<span v-else-if="i >= currentIndex">{{i + 1}}</span>
				<i v-else class="tf tf-check"></i>
			</div>
			<p>{{item.name}}</p>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			node: Array,
			
			current: {
				type: [String, Number],
				default: 1
			},
			
			reject: [String, Number]
		},
		
		computed: {
			currentIndex() {
				if (this.current == this.node.length) return this.node.length + 1;
				return parseInt(this.reject || this.current) - 1;
			}
		}
	}
</script>

<style lang="stylus" scoped>
	@import '~@/assets/style/mobile'
	
	.g-flow {
		height: 140px;
		padding-bottom: 20px;
		
		mobile({
			display: block,
			height: auto,
			padding: .3rem 0 .4rem,
			margin-top: 0,
			overflow: auto,
			font-size: 0,
			white-space: nowrap
		})
		
		& + >>> .c-detail {
			mobile({
				margin-top: 0
			})
		}
		
		mobile({
			background: #fff
		})
		
		.item {
			position: relative;
			width: 32px;
			
			mobile({
				display: inline-block,
				width: .64rem,
				padding: 0 .9rem .5rem,
				margin-left: 0 !important
			})
			
			&:nth-child(n+2) {
				margin-left: 172px;
				
				&:before {
					content: '';
					position: absolute;
					right: 32px;
					top: 15px;
					width: 172px;
					height: 2px;
					background: #1492FF;
			
					mobile({
						right: 1.54rem,
						width: 1.8rem,
						height: .04rem
					})
				}
			}
			
			&.z-crt {
				~ .item {
					&:before {
						background: #ccc;
					}
					
					.icon {
						border-color: #ccc;
						color: #999;
					}
					
					p {
						color: #666;
					}
				}
				
				.icon {
					border-color: #1492FF;
					color: #fff;
					background: #1492FF;
				}
				
				p {
					font-weight: bold;
					color: #000;
				}
			}
			
			&.z-err {
				&:before {
					background: #E63633;
				}
				
				.icon {
					border-color: #E63633;
					color: #E63633;
					background: #fff;
				}
				
				p {
					font-weight: normal;
					color: #E63633;
				}
			}
			
			.icon {
				box-sizing: border-box;
				height: 32px;
				border: 2px solid #1492FF;
				border-radius: 100%;
				font-size: 16px;
				color: #1492FF;
				background: #fff;
				
				.tf {
					font-size: 14px;
				}
			}
			
			p {
				position: absolute;
				left: 50%;
				top: 42px;
				font-size: 15px;
				white-space: nowrap;
				color: #020202;
				transform: translate(-50%,0);
				
				mobile({
					top: .84rem,
					font-size: .28rem
				})
			}
		}
	}
</style>
