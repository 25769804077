<template>
	<c-form ref="form">
		<c-form-item type="hidden" name="project_id"></c-form-item>
		<c-form-item type="select" label="中标企业" name="zbqy_id" options="COMPANY_LIST" required search></c-form-item>
		<c-form-item type="number" decimal="2" label="中标金额" name="zb_money" required input-word="元"></c-form-item>
		<c-form-item v-model="fileType" type="radio" label="中标通知书" name="yz_qs_type" required :options="fileTypeOption"></c-form-item>
		<c-form-item v-show="fileType == 1" type="file" label="上传文件" name="zb_path" required file-type="word,excel,pdf,zip,image"></c-form-item>
		<c-form-item label="需购买保函" name="bx_type_ids" required>
			<c-checkbox-group v-model="currentInsurance">
				<c-checkbox v-for="(item, i) in insuranceType" :key="i" :value="item.value">{{item.name}}</c-checkbox>
			</c-checkbox-group>
		</c-form-item>
		<c-form-item type="datetime" label="签约截止日" name="qy_last_date" required></c-form-item>
		<c-form-item type="number" label="工期" name="sggq" input-word="天" required></c-form-item>
		<c-form-item type="input" label="被保险人" name="bbxr_name" required></c-form-item>
		<c-form-item type="input" label="被保险人组织代码" name="bbxr_jgdm" required></c-form-item>
		<c-form-item type="textarea" label="备注说明" name="zb_remark"></c-form-item>
	</c-form>
</template>

<script>
	import {mapState} from 'vuex'
	
	export default {
		data() {
			return {
				fileType: '',
				fileTypeOption: [{
					name: '手动上传',
					value: 1
				}, {
					name: '电子公章签署',
					value: 2
				}],
				insuranceType: [],
				currentInsurance: []
			}
		},
		
		computed: {
			...mapState(['userInfo'])
		},
		
		mounted() {
			this.request({
				url: '/bx_type/list',
				success: data => {
					this.insuranceType = Array.from(data, item => {
						return {
							name: item.type_name,
							value: item.type_id
						}
					});
					this.$nextTick(() => {
						this.currentInsurance = Array.from(data, item => item.type_id);
					});
				}
			});
		},
		
		methods: {
			edit(id) {
				this.$refs.form.set({
					project_id: id,
					bbxr_name: this.userInfo.name,
					bbxr_jgdm: this.userInfo.yzzzh
				});
				this.currentInsurance = Array.from(this.insuranceType, item => item.value);
			},
			
			submit() {
				return new Promise(resolve => {
					this.$refs.form.submit({
						url: '/project/set_zbqy',
						rule: {
							zbqy_id: '请选择中标企业',
							zb_money: '请填写中标金额',
							yz_qs_type: '请选择中标通知书类型',
							zb_path: [{
								type: 'empty',
								word: '请上传中标通知书',
								skip: () => {
									return this.fileType != 1;
								}
							}],
							qy_last_date: '请选择签约截止日',
							sggq: '请填写工期',
							bbxr_name: '请填写被保险人',
							bbxr_jgdm: '请填写被保险人组织代码'
						},
						dataFormatter: data => {
							data.bx_type_ids = this.currentInsurance;
							return data;
						},
						success: resolve
					});
				})
			}
		}
	};
</script>

<style lang="stylus" scoped>
</style>