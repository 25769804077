<template>
	<c-dialog ref="dialog" fadein title="短信二次验证" width="450" height="220" :button-name="buttonName" :cancel-button="false" @resolve="submitHandle" @close="closeHandle">
		<div class="content">
			<p>{{scene ? '授权码' : '验证码'}}已发送至手机<span>{{privatePhone}}</span></p>
			<div class="code flex">
				<input v-model="code" class="flex-grow">
				<button :disabled="!!countdown" @click="getSmscode">{{countdown ? `${countdown}s` : countdown === false ? `获取${scene ? '授权码' : '验证码'}` : '重新获取'}}</button>
			</div>
			<span v-if="scene" class="tips">授权码2小时内有效，请勿重复获取</span>
		</div>
	</c-dialog>
</template>

<script>
	export default {
		props: {
			scene: [String, Number],
			business: [String, Number],
			
			buttonName: {
				type: String,
				default: '确认'
			},
			
			autoSend: Boolean
		},
		
		data() {
			return {
				phone: '',
				code: '',
				countdown: false
			}
		},
		
		computed: {
			privatePhone(state) {
				var arr = this.phone.split('');
				arr.splice(3, 4, '****');
				return arr.join('');
			}
		},
		
		methods: {
			open(phone) {
				this.phone = phone || this.$store.state.userInfo.phone;
				this.$refs.dialog.open();
				this.autoSend && this.getSmscode();
			},
			
			getSmscode() {
				if (this.scene) {
					this.request({
						url: '/verify/send_yz_seal_code',
						data: {
							businessType: this.scene,
							businessId: this.business
						},
						loading: true,
						success: data => {
							this.countdownHandle();
						}
					});
				} else {
					this.$emit('getSmscode', this.countdownHandle);
				}
			},
			
			countdownHandle() {
				let that = this;
				!function loop(s) {
					that.countdown = s;
					
					if (s) {
						that._countdownTimer = setTimeout(function() {
							loop(--s);
						}, 1000);
					}
				}(60);
			},
			
			submitHandle(stop, next) {
				stop();
				this.$emit('submit', this.code, next);
			},
			
			closeHandle() {
				this.code = '';
				this.countdown = false;
				clearTimeout(this._countdownTimer);
			}
		}
	}
</script>

<style lang="stylus" scoped>
	@import '~@/assets/style/mobile'
	
	.content {
		width: 286px;
		margin: 50px 0 0 70px;
		
		mobile({
			margin: 1rem .6rem 0
		})
		
		p {
			font-size: 16px;
			color: #333;
			
			mobile({
				font-size: .3rem
			})
			
			span {
				margin-left: .4em;
				color: #F50000;
			}
		}
		
		.code {
			height: 40px;
			margin-top: 20px;
			
			mobile({
				height: .8rem,
				margin-top: .4rem
			})
			
			input {
				box-sizing: border-box;
				height: 100%;
				border: 1px solid #ddd;
				padding: 0 10px;
				
				&:focus {
					position: relative;
					border-color: #1492FF;
				}
			}
			
			button {
				width: 90px;
				border: 1px solid #ddd;
				margin-left: -1px;
				font-size: 14px;
				color: #1492FF;
				background: #fff;
				
				mobile({
					width: 1.6rem,
					font-size: .26rem
				})
				
				&:disabled {
					color: #999;
					background: #F6F6F6;
					cursor: not-allowed;
				}
				
				&:not(:disabled):hover {
					position: relative;
					border-color: #1492FF;
				}
			}
		}
		
		.tips {
			display: block;
			margin-top: 14px;
			font-size: 14px;
			color: #666;
		}
	}
</style>