<template>
	<c-dialog ref="dialog" fadein :title="approve ? '审批通过' : '驳回申请'" width="650" height="300" :button-name="approve ? '确认通过' : '确认驳回'" @resolve="submitHandle">
		<div class="content">
			<c-form>
				<slot :state="approve"></slot>
				<c-form class="remark">
					<c-form-item v-show="approve" v-model="remark" type="textarea" label="审批备注" placeholder="审批说明写这里（非必填）"></c-form-item>
					<c-form-item v-show="!approve" v-model="remark" type="textarea" label="驳回原因" required placeholder="驳回原因写这里（必填）"></c-form-item>
				</c-form>
			</c-form>
		</div>
	</c-dialog>
</template>

<script>
	export default {
		data() {
			return {
				approve: false,
				remark: ''
			}
		},
		
		methods: {
			resolve(state = true) {
				this.approve = state;
				this.remark = '';
				this.$refs.dialog.open();
			},
			
			reject() {
				this.resolve(false);
			},
			
			submitHandle(stop, next) {
				if (!this.approve && !this.remark) {
					stop();
					this.$message({
						text: '请填写驳回原因'
					});
					return;
				}
				this.$emit('resolve', this.approve, this.remark, stop, next);
			}
		}
	}
</script>

<style lang="stylus" scoped>
	.content {
		padding: 34px 30px;
		
		>>> .c-form {
			padding: 0;
		}
		
		.remark >>> .c-form-content textarea {
			height: 158px;
			border: none;
			padding: 9px 12px;
			background: #FAFAFA;
		}
	}
</style>