<template>
	<app>
		<flow-node :node="nodeList" :current="currentNode" :reject="rejectNode"></flow-node>
		<c-detail>
			<c-detail-item label="项目名称">
				{{detailInfo.name}}
			</c-detail-item>
			<c-detail-item label="项目状态">
				<span v-if="projectState[detailInfo.state]" :style="{'color': projectState[detailInfo.state].color}">{{projectState[detailInfo.state].name}}</span>
			</c-detail-item>
			<c-detail-item label="项目编号">
				{{detailInfo.code}}
			</c-detail-item>
			<c-detail-item label="项目所属行业">
				{{detailInfo.sshy}}
			</c-detail-item>
			<c-detail-item label="建设地址">
				{{detailInfo.xm_address}}
			</c-detail-item>
			<c-detail-item label="项目分管领导">
				{{detailInfo.yzdw_fgld_name}}
			</c-detail-item>
			<c-detail-item label="业主单位">
				{{detailInfo.yzdw_name}}
			</c-detail-item>
			<c-detail-item label="业主单位地址">
				{{detailInfo.yzdw_address}}
			</c-detail-item>
			<c-detail-item label="业主单位联系人">
				{{detailInfo.yzdw_lxr}}<span v-if="detailInfo.yzdw_lxdh">（{{detailInfo.yzdw_lxdh}}）</span>
			</c-detail-item>
			<c-detail-item label="被保险人">
				{{detailInfo.bbxr_name}}
			</c-detail-item>
			<c-detail-item label="被保险人组织代码">
				{{detailInfo.bbxr_jgdm}}
			</c-detail-item>
			<c-detail-item label="招标文件">
				<c-file :src="detailInfo.files_path"></c-file>
			</c-detail-item>
			<c-detail-item label="招标代理费付款方">
				{{detailInfo.zbdl_money_user_type == 1 ? '企业' : '业主'}}
			</c-detail-item>
			<c-detail-item label="备注说明">
				{{detailInfo.remark}}
			</c-detail-item>
		</c-detail>
		
		<c-detail v-if="detailInfo.state == 4" title="中标企业信息">
			<c-detail-item label="工期">
				<span v-if="detailInfo.sggq">{{detailInfo.sggq}}天</span>
			</c-detail-item>
			<c-detail-item label="中标金额">
				{{detailInfo.zb_money}}
			</c-detail-item>
			<c-detail-item label="中标通知书">
				<c-file v-if="detailInfo.zb_path" :src="detailInfo.zb_path"></c-file>
			</c-detail-item>
			<c-detail-item label="中标单位">
				{{detailInfo.zbqy_name}}
			</c-detail-item>
			<c-detail-item label="中标单位地址">
				{{detailInfo.zbqy_address}}
			</c-detail-item>
			<c-detail-item label="中标企业法人">
				{{detailInfo.zbqy_user_realname}}
			</c-detail-item>
			<c-detail-item label="中标企业电话">
				{{detailInfo.zbqy_user_phone}}
			</c-detail-item>
			<c-detail-item label="监理单位">
				{{detailInfo.jldw_name}}
			</c-detail-item>
			<c-detail-item label="监理单位地址">
				{{detailInfo.jldw_address}}
			</c-detail-item>
			<c-detail-item label="企需购买保函">
				<b style="color: #E63633;">{{insurance}}</b>
			</c-detail-item>
			<c-detail-item label="项目签约截止日">
				{{detailInfo.qy_last_date}}
			</c-detail-item>
			<c-detail-item label="项目经理">
				{{detailInfo.xmjl_xm}}<span v-if="detailInfo.xmjl_lxdh">（{{detailInfo.xmjl_lxdh}}）</span>
			</c-detail-item>
			<c-detail-item label="技术总工">
				{{detailInfo.jszg_xm}}<span v-if="detailInfo.jszg_lxdh">（{{detailInfo.jszg_lxdh}}）</span>
			</c-detail-item>
			<c-detail-item label="安全员">
				{{detailInfo.aqy_xm}}<span v-if="detailInfo.aqy_lxdh">（{{detailInfo.aqy_lxdh}}）</span>
			</c-detail-item>
			<c-detail-item label="施工员">
				{{detailInfo.sgy_xm}}<span v-if="detailInfo.sgy_lxdh">（{{detailInfo.sgy_lxdh}}）</span>
			</c-detail-item>
			<c-detail-item label="质检员">
				{{detailInfo.zjy_xm}}<span v-if="detailInfo.zjy_lxdh">（{{detailInfo.zjy_lxdh}}）</span>
			</c-detail-item>
			<c-detail-item label="备注说明">
				{{detailInfo.zb_remark}}
			</c-detail-item>
		</c-detail>
		
		<c-detail v-if="detailInfo.state == 4" title="项目关联保函">
			<div slot="custom">
				<detail-card-m v-for="(item, i) in insuranceList" :key="i">
					<div class="flex-between-center">
						<div class="name">{{item.bx_type_name}}</div>
						<div class="state" :style="{'color': insuranceState[item.state].color}">{{insuranceState[item.state].name}}</div>
					</div>
					<div class="info">被保险人：{{item.bbxr_name}}</div>
					<div v-if="item.bd_money" class="info">保险额度：{{item.bd_money}}元</div>
					<div v-if="item.bx_bd_state_date" class="info">保单有效期：{{item.bx_bd_state_date}}至{{item.bx_bd_end_date}}</div>
					<div v-if="item.bx_bd_state_date" class="info">
						距离保单过期：<span v-if="item.exp_date >= 0" style="color: #f00;">还有{{item.exp_date}}天</span>
						<span v-else style="color: #f00;">已过期</span>
					</div>
				</detail-card-m>
			</div>
		</c-detail>
		
		<c-detail v-if="detailInfo.state == 4" title="合同文件">
			<div slot="custom">
				<detail-card-m v-for="(item, i) in contractList" :key="i">
					<div class="flex-between-center">
						<div class="code">编号{{item.code}}</div>
						<div class="state" v-if="contractState[item.qs_state]" :style="{'color': contractState[item.qs_state].color}">{{contractState[item.qs_state].name}}</div>
					</div>
					<div class="name">{{item.name}}</div>
					<c-file :src="item.curr_file_path"></c-file>
					<div class="info">上传时间：{{item.create_date}}</div>
				</detail-card-m>
			</div>
		</c-detail>
		
		<c-detail v-if="detailInfo.state == 4" title="开工令">
			<div slot="custom">
				<detail-card-m v-for="(item, i) in proceedList" :key="i">
					<div class="flex-between-center">
						<c-file :src="item.kgl_path"></c-file>
						<div class="state" v-if="proceedState[item.state]" :style="{'color': proceedState[item.state].color}">{{proceedState[item.state].name}}</div>
					</div>
					<div class="info">上传时间：{{item.create_date}}</div>
				</detail-card-m>
			</div>
		</c-detail>
		
		<c-detail title="创建流程">
			<timeline :data="timeline"></timeline>
		</c-detail>
			
		<button-list-m v-if="biddingButton" slot="foot">
			<c-button size="m" @click="navigateTo('/project_bidding', {id})">设置中标企业</c-button>
		</button-list-m>
			
		<button-list-m v-if="approveButton || signButton" slot="foot">
			<c-button color="green" size="m" @click="$refs.sms.open(approveButton ? userInfo.fgld_phone : userInfo.seal_phone)">{{approveButton ? '审批通过' : '签署'}}</c-button>
			<c-button color="red" size="m" @click="$refs.approve.reject()">驳回</c-button>
		</button-list-m>
		
		<approve ref="approve" @resolve="approveHandle"></approve>
		
		<smscode ref="sms" :business="id" :scene="approveButton ? 3 : 33" :buttonName="approveButton ? '确认通过' : '确认并签署'" @submit="approveHandle(arguments[0], '', false, arguments[1])"></smscode>
	</app>
</template>

<script>
	import flowNode from '@/components/module/flow_node.vue'
	import timeline from '@/components/module/timeline.vue'
	import approve from '@/components/module/approve.vue'
	import smscode from '@/components/module/smscode.vue'
	import {mapState, mapGetters} from 'vuex'
	
	export default {
		components: {
			flowNode,
			timeline,
			approve,
			smscode
		},
		
		props: {
			id: String
		},
		
		data() {
			return {
				nodeList: [{
					name: '创建项目'
				}, {
					name: '待业主确认'
				}, {
					name: '设置中标企业'
				}, {
					name: '待业主领导审批'
				}, {
					name: '待业主签署'
				}, {
					name: '创建成功'
				}],
				detailInfo: {},
				insurance: '',
				insuranceList: [],
				contractList: [],
				proceedList: [],
				timeline: []
			}
		},
		
		computed: {
			...mapState(['userInfo']),
			...mapGetters(['projectState', 'insuranceState', 'contractState', 'proceedState']),
			
			currentNode() {
				return {
					31: 4,
					32: 5,
					4: 6
				}[this.detailInfo.state] || this.detailInfo.state;
			},
			
			rejectNode() {
				return this.detailInfo.state == 1 ? 2 : 0;
			},
			
			biddingButton() {
				return this.detailInfo.state == 3 && this.detailInfo.zbdl_money_user_type == 2;
			},
			
			approveButton() {
				return this.detailInfo.state == 31;
			},
			
			signButton() {
				return this.detailInfo.state == 32;
			}
		},
		
		mounted() {
			this.updateDetail();
		},
		
		methods: {
			updateDetail() {
				this.request({
					url: '/project/detail',
					data: {
						project_id: this.id
					},
					success: data => {
						this.detailInfo = data.project_info;
						this.insurance = Array.from(data.bx_list, item => {
							return item.bx_type_name;
						}).join('，');
						this.insuranceList = data.bx_list;
						this.contractList = data.ht_list;
						this.proceedList = data.kgl_list;
						this.timeline = Array.from(data.logList, item => {
							return {
								date: item.create_date,
								content: `[${item.create_user_name}] ${item.remark}`
							}
						});
					}
				});
			},
			
			approveHandle(code, remark, stop, next) {
				stop && stop();
				
				this.request({
					url: this.approveButton ? '/project/check_yzld' : '/project/check_qs',
					data: {
						project_id: this.id,
						state: code ? 3 : 1,
						reject_remark: remark,
						verify_code: code
					},
					loading: code && this.signButton ? '请稍等，正在进行在线签章操作...' : true,
					success: data => {
						next();
						this.$message({
							text: '审批成功',
							resolve: () => {
								this.updateDetail();
							}
						});
					}
				});
			}
		}
	};
</script>

<style lang="stylus" scoped>
</style>