<template>
	<page>
		<module title="项目详情">
			<flow-node :node="nodeList" :current="currentNode" :reject="rejectNode"></flow-node>
			<c-detail>
				<c-detail-item label="项目名称">
					{{detailInfo.name}}
				</c-detail-item>
				<c-detail-item label="项目状态">
					<span v-if="projectState[detailInfo.state]" :style="{'color': projectState[detailInfo.state].color}">{{projectState[detailInfo.state].name}}</span>
				</c-detail-item>
				<c-detail-item label="项目编号">
					{{detailInfo.code}}
				</c-detail-item>
				<c-detail-item label="项目分管领导">
					{{detailInfo.yzdw_fgld_name}}
				</c-detail-item>
				<c-detail-item label="项目所属行业">
					{{detailInfo.sshy}}
				</c-detail-item>
				<c-detail-item label="业主单位">
					{{detailInfo.yzdw_name}}
				</c-detail-item>
				<c-detail-item label="建设地址">
					{{detailInfo.xm_address}}
				</c-detail-item>
				<c-detail-item label="业主单位地址">
					{{detailInfo.yzdw_address}}
				</c-detail-item>
				<c-detail-item label="被保险人">
					{{detailInfo.bbxr_name}}
				</c-detail-item>
				<c-detail-item label="业主单位联系人">
					{{detailInfo.yzdw_lxr}}<span v-if="detailInfo.yzdw_lxdh">（{{detailInfo.yzdw_lxdh}}）</span>
				</c-detail-item>
				<c-detail-item label="被保险人组织代码">
					{{detailInfo.bbxr_jgdm}}
				</c-detail-item>
				<c-detail-item label="招标文件">
					<c-file :src="detailInfo.files_path"></c-file>
				</c-detail-item>
				<c-detail-item label="招标代理费付款方">
					{{detailInfo.zbdl_money_user_type == 1 ? '企业' : '业主'}}
				</c-detail-item>
				<c-detail-item label="备注说明">
					{{detailInfo.remark}}
				</c-detail-item>
			</c-detail>
			
			<c-detail v-if="detailInfo.state == 4" title="中标企业信息">
				<c-detail-item label="工期">
					<span v-if="detailInfo.sggq">{{detailInfo.sggq}}天</span>
				</c-detail-item>
				<c-detail-item label="中标单位">
					{{detailInfo.zbqy_name}}
				</c-detail-item>
				<c-detail-item label="中标金额">
					{{detailInfo.zb_money}}
				</c-detail-item>
				<c-detail-item label="中标单位地址">
					{{detailInfo.zbqy_address}}
				</c-detail-item>
				<c-detail-item label="中标通知书">
					<c-file v-if="detailInfo.zb_path" :src="detailInfo.zb_path"></c-file>
				</c-detail-item>
				<c-detail-item label="中标企业法人">
					{{detailInfo.zbqy_user_realname}}
				</c-detail-item>
				<c-detail-item label="项目经理">
					{{detailInfo.xmjl_xm}}<span v-if="detailInfo.xmjl_lxdh">（{{detailInfo.xmjl_lxdh}}）</span>
				</c-detail-item>
				<c-detail-item label="中标企业联系电话">
					{{detailInfo.zbqy_user_phone}}
				</c-detail-item>
				<c-detail-item label="技术总工">
					{{detailInfo.jszg_xm}}<span v-if="detailInfo.jszg_lxdh">（{{detailInfo.jszg_lxdh}}）</span>
				</c-detail-item>
				<c-detail-item label="监理单位">
					{{detailInfo.jldw_name}}
				</c-detail-item>
				<c-detail-item label="安全员">
					{{detailInfo.aqy_xm}}<span v-if="detailInfo.aqy_lxdh">（{{detailInfo.aqy_lxdh}}）</span>
				</c-detail-item>
				<c-detail-item label="监理单位地址">
					{{detailInfo.jldw_address}}
				</c-detail-item>
				<c-detail-item label="施工员">
					{{detailInfo.sgy_xm}}<span v-if="detailInfo.sgy_lxdh">（{{detailInfo.sgy_lxdh}}）</span>
				</c-detail-item>
				<c-detail-item label="企业需购买保函">
					<b style="color: #E63633;">{{insurance}}</b>
				</c-detail-item>
				<c-detail-item label="质检员">
					{{detailInfo.zjy_xm}}<span v-if="detailInfo.zjy_lxdh">（{{detailInfo.zjy_lxdh}}）</span>
				</c-detail-item>
				<c-detail-item label="项目签约截止日">
					{{detailInfo.qy_last_date}}
				</c-detail-item>
				<c-detail-item label="备注说明" full>
					{{detailInfo.zb_remark}}
				</c-detail-item>
			</c-detail>
			
			<c-detail v-if="detailInfo.state == 4" title="项目关联保函">
				<c-table slot="custom" :value="insuranceList" height="auto" skin="detail">
					<c-table-column
						label="状态"
						width="80"
					>
						<template v-slot="{data}">
							<span v-if="insuranceState[data.state]" :style="{'color': insuranceState[data.state].color}">{{insuranceState[data.state].name}}</span>
						</template>
					</c-table-column>
					
					<c-table-column
						label="保函类型"
						name="bx_type_name"
						width="100"
					></c-table-column>
					
					<c-table-column
						label="被保险人"
						name="yzdw_name"
						width="200"
					></c-table-column>
					
					<c-table-column
						label="保单额度"
						width="140"
					>
						<template v-slot="{data}">
							<span v-if="data.bd_money">￥{{data.bd_money}}</span>
						</template>
					</c-table-column>
					
					<c-table-column
						label="金融机构"
						name="bxgs_name"
						width="300"
					></c-table-column>
					
					<c-table-column
						label="保单有效期限"
						width="170"
					>
						<template v-slot="{data}">
							<span v-if="data.bx_bd_state_date">{{data.bx_bd_state_date}}至{{data.bx_bd_end_date}}
								<span v-if="data.exp_date >= 0" style="color: #f00;">（还有{{data.exp_date}}天）</span>
								<span v-else style="color: #f00;">（已过期）</span>
							</span>
						</template>
					</c-table-column>
				</c-table>
			</c-detail>
			
			<c-detail v-if="detailInfo.state == 4" title="合同文件">
				<c-table slot="custom" :value="contractList" height="auto" skin="detail">
					<c-table-column
						label="状态"
						width="80"
					>
						<template v-slot="{data}">
							<span v-if="contractState[data.qs_state]" :style="{'color': contractState[data.qs_state].color}">{{contractState[data.qs_state].name}}</span>
						</template>
					</c-table-column>
					
					<c-table-column
						label="合同编号"
						name="code"
						width="100"
					></c-table-column>
					
					<c-table-column
						label="合同名称"
						name="name"
						width="180"
					></c-table-column>
					
					<c-table-column
						label="文件路径"
						width="300"
					>
						<template v-slot="{data}">
							<c-file :src="data.curr_file_path"></c-file>
						</template>
					</c-table-column>
					
					<c-table-column
						label="上传时间"
						name="create_date"
						width="180"
					></c-table-column>
				</c-table>
			</c-detail>
			
			<c-detail v-if="detailInfo.state == 4" title="开工令">
				<c-table slot="custom" :value="proceedList" height="auto" skin="detail">
					<c-table-column
						label="状态"
						width="80"
					>
						<template v-slot="{data}">
							<span v-if="proceedState[data.state]" :style="{'color': proceedState[data.state].color}">{{proceedState[data.state].name}}</span>
						</template>
					</c-table-column>
					
					<c-table-column
						label="文件路径"
						width="300"
					>
						<template v-slot="{data}">
							<c-file :src="data.kgl_path"></c-file>
						</template>
					</c-table-column>
					
					<c-table-column
						label="监理单位"
						name="jldw_name"
						width="180"
					></c-table-column>
					
					<c-table-column
						label="上传时间"
						name="create_date"
						width="180"
					></c-table-column>
				</c-table>
			</c-detail>
			
			<c-detail title="创建流程">
				<c-detail-item label="创建流程" full>
					<timeline :data="timeline"></timeline>
				</c-detail-item>
			</c-detail>
			
			<c-button v-if="biddingButton" slot="button" size="m" width="300" @click="biddingHandle">设置中标企业</c-button>
			<div v-if="approveButton || signButton" slot="button" class="flex-center">
				<c-button color="green" size="m" width="300" @click="$refs.sms.open(approveButton ? userInfo.fgld_phone : userInfo.seal_phone)">{{approveButton ? '审批通过' : '签署'}}</c-button>
				<c-button color="red" size="m" @click="$refs.approve.reject()">驳回</c-button>
			</div>
		</module>
		
		<c-dialog ref="projectBidding" title="设置中标企业" width="600" button-name="确认并提交" @resolve="biddingSubmitHandle">
			<project-bidding ref="projectBiddingForm"></project-bidding>
		</c-dialog>
		
		<approve ref="approve" @resolve="approveHandle"></approve>
		
		<smscode ref="sms" :business="id" :scene="approveButton ? 3 : 33" :buttonName="approveButton ? '确认通过' : '确认并签署'" @submit="approveHandle(arguments[0], '', false, arguments[1])"></smscode>
	</page>
</template>

<script>
	import projectBidding from './project_bidding_form.vue'
	import flowNode from '@/components/module/flow_node.vue'
	import timeline from '@/components/module/timeline.vue'
	import approve from '@/components/module/approve.vue'
	import smscode from '@/components/module/smscode.vue'
	import {mapState, mapGetters} from 'vuex'
	
	export default {
		components: {
			projectBidding,
			flowNode,
			timeline,
			approve,
			smscode
		},
		
		props: {
			id: String
		},
		
		data() {
			return {
				nodeList: [{
					name: '创建项目'
				}, {
					name: '待业主确认'
				}, {
					name: '设置中标企业'
				}, {
					name: '待业主领导审批'
				}, {
					name: '待业主签署'
				}, {
					name: '创建成功'
				}],
				detailInfo: {},
				insurance: '',
				insuranceList: [],
				contractList: [],
				proceedList: [],
				timeline: []
			}
		},
		
		computed: {
			...mapState(['userInfo']),
			...mapGetters(['projectState', 'insuranceState', 'contractState', 'proceedState']),
			
			currentNode() {
				return {
					31: 4,
					32: 5,
					4: 6
				}[this.detailInfo.state] || this.detailInfo.state;
			},
			
			rejectNode() {
				return this.detailInfo.state == 1 ? 2 : 0;
			},
			
			biddingButton() {
				return this.detailInfo.state == 3 && this.detailInfo.zbdl_money_user_type == 2;
			},
			
			approveButton() {
				return this.detailInfo.state == 31;
			},
			
			signButton() {
				return this.detailInfo.state == 32;
			}
		},
		
		watch: {
			id() {
				this.updateDetail();
			}
		},
		
		mounted() {
			this.updateDetail();
		},
		
		methods: {
			updateDetail() {
				this.request({
					url: '/project/detail',
					data: {
						project_id: this.id
					},
					success: data => {
						this.detailInfo = data.project_info;
						this.insurance = Array.from(data.bx_list, item => {
							return item.bx_type_name;
						}).join('，');
						this.insuranceList = data.bx_list;
						this.contractList = data.ht_list;
						this.proceedList = data.kgl_list;
						this.timeline = Array.from(data.logList, item => {
							return {
								date: item.create_date,
								content: `[${item.create_user_name}] ${item.remark}`
							}
						});
					}
				});
			},
			
			biddingHandle() {
				this.$refs.projectBiddingForm.edit(this.id);
				this.$refs.projectBidding.open();
			},
			
			biddingSubmitHandle(stop, next) {
				stop();
				
				this.$refs.projectBiddingForm.submit().then(() => {
					next();
					this.$message({
						text: '提交成功'
					});
					this.updateDetail();
				});
			},
			
			approveHandle(code, remark, stop, next) {
				stop && stop();
				
				this.request({
					url: this.approveButton ? '/project/check_yzld' : '/project/check_qs',
					data: {
						project_id: this.id,
						state: code ? 3 : 1,
						reject_remark: remark,
						verify_code: code
					},
					loading: code && this.signButton ? '请稍等，正在进行在线签章操作...' : true,
					success: data => {
						next();
						this.$message({
							text: '审批成功',
							resolve: () => {
								this.updateDetail();
							}
						});
					}
				});
			}
		}
	};
</script>

<style lang="stylus" scoped>
</style>