<template>
	<ul v-if="data" class="g-timeline">
		<li v-for="(item, i) in data" :key="i" class="item">
			<span class="date">{{item.date}}</span>
			<p>{{item.content}}</p>
			<div v-if="item.reject" class="reject">{{item.reject}}</div>
		</li>
	</ul>
</template>

<script>
	export default {
		props: {
			data: [Array, String, Number]
		}
	}
</script>

<style lang="stylus" scoped>
	@import '~@/assets/style/mobile'
	
	.g-timeline {
		padding-left: 190px;
		
		mobile({
			padding-left: .35rem,
			padding-right: .24rem,
			margin: .2rem 0
		})
		
		li {
			position: relative;
			border-left: 1px solid #eee;
			padding: 3px 0 3px 20px;
			
			mobile({
				padding-top: .2rem
			})
			
			&:nth-last-child(1) {
				&:before {
					border-color: #1492FF;
				}
			}
			
			&:before {
				content: '';
				position: absolute;
				left: -6px;
				top: 14px;
				width: 9px;
				height: 9px;
				border: 2px solid #ddd;
				border-radius: 100%;
				background: #fff;
		
				mobile({
					left: -.12rem,
					top: .31rem,
					box-sizing: border-box,
					width: .24rem,
					height: .24rem,
					border-width: .04rem
				})
			}
			
			.date {
				margin-right: 20px;
				line-height: 40px;
				font-size: 15px;
				white-space: nowrap;
				color: #999;
				
				normal({
					position: absolute,
					right: 100%,
					top: 0
				})
				
				mobile({
					display: block,
					line-height: .5rem,
					font-size: .26rem
				})
			}
			
			p {
				line-height: 34px;
				font-size: 15px;
				color: #000;
				
				mobile({
					line-height: .5rem,
					font-size: .3rem
				})
			}
			
			.reject {
				padding: 10px 12px;
				line-height: 24px;
				font-size: 15px;
				color: #E63633;
				background: #FAFAFA;
			}
		}
	}
</style>
